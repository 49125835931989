import { ReferenceItem, TrackedObject, datetime, uuid, VocabularyEntry } from './core';
import { FrontUser, ScreeningEvaluation } from './user';
import { Movie } from './work';
import { EventDetails } from './manifestation';

export type VideoProduct = ReferenceItem & VideoProductData & TrackedObject;

export enum VideoFormat {
    SD,
    HD,
    UHD,
}

export interface VideoProductData {
    type: VideoType;
    videoFormats: VideoFormat[];
    audioLanguages?: CatalogLanguage[];
    subtitleLanguages?: CatalogLanguage[];
    movie?: Movie;
    additionalInfo?: string;
}

export type VideoProductUpdate = ReferenceItem & VideoProductData;

export type VideoType = ReferenceItem & VideoTypeData & TrackedObject;

export enum ProductType {
    PHYSICAL = 'physical',
    DIGITAL = 'digital',
    DEMATERIALIZED = 'dematerialized',
    OTHER = 'other',
}

export interface VideoTypeData extends VocabularyEntry {
    productType: ProductType;
}

export type VideoTypeUpdate = ReferenceItem & VideoTypeData;

export type CatalogLanguage = ReferenceItem & CatalogLanguageData & TrackedObject;

export type CatalogLanguageData = VocabularyEntry;

export type CatalogLanguageUpdate = ReferenceItem & CatalogLanguageData;

export type Order = ReferenceItem & OrderData & TrackedObject;

export enum ShippingMode {
    SUITCASE = 'suitcase',
    PRIVATE_CARRIER = 'private-carrier',
}

export enum OrderStatus {
    VALIDATED = 'validated',
    CANCELED = 'canceled',
    PENDING = 'pending',
    STAGING = 'staging',
}

export type PhysicalOrderStatus = 'pending' | 'validated' | 'archived';

export interface ShippingDetails {
    mode: ShippingMode | null;
    deliveryDate?: datetime;
    returnDate?: datetime;
    carrierName?: string;
    accountNumber?: string;
    address?: string;
    postOffice?: string;
    dcpEmail?: string;
}

export interface SendLinkDetails {
    dcpEmail?: string;
}

export interface OrderData {
    user: FrontUser;
    status: OrderStatus;
    orderedAt: datetime;
    eventDetails?: EventDetails;
    shippingDetails?: ShippingDetails;
    lines?: OrderLine[]; // FIXME : this is not in commerce.yaml schema, but added by Guillaume. Why?
}

export type OrderLine = ReferenceItem & OrderLineData & TrackedObject;

export interface OrderLineData {
    order: Order;
    videoProduct: VideoProduct;
}

export interface OrderLineWithScreeningEvaluationDTO extends OrderLine {
    screeningEvaluation: ScreeningEvaluation;
}

export interface CartEntry {
    videoProduct: VideoProduct;
    subtitlesLanguage?: CatalogLanguage;
    quantity?: number;
}

// [VideoProduct.id, CatalogLanguageData.code?]
export type CartLocalStorageEntry = [uuid, string?, number?];
