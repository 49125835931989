import { MovieActionType } from './MovieActionType';
import { Contribution, Movie, MovieAsset } from '../../domain/work';
import { MovieAction } from './MovieActions';
import { Cycle } from '../../domain/editorial';
import { LegalContract } from '../../domain/legal';
import { VideoProduct } from '../../domain/commerce';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Review } from '../../domain/community';

export interface MovieStateData extends Movie {
    cycles?: Cycle[];
    legalContracts?: LegalContract[];
    videoProducts?: VideoProduct[];
    resources?: MovieAsset[];
    relatedMovies?: Movie[];
    reviews?: Review[];
    contributions?: Contribution[];
}

export type MovieState = FetchState<MovieStateData>;

export const initialState: MovieState = {
    isLoading: false,
};

export const movieReducer = (state: MovieState = initialState, action: MovieAction): MovieState => {
    switch (action.type) {
        case MovieActionType.FETCH_MOVIE:
            return {
                ...state,
                isLoading: true,
            };

        case MovieActionType.FETCH_MOVIE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: action.payload as MovieStateData,
            };

        case MovieActionType.FETCH_MOVIE_ERROR:
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: action.payload as RequestError,
            };

        default:
            return state;
    }
};
