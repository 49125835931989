export type datetime = string;
export type uuid = string;
export type uri = string;

export interface ApiInfo {
    name: string;
    version: string;
}

export interface TimeStampedObject {
    // Original date when the content has been created
    createdAt: datetime;
    // Date when the content has been updated
    updatedAt: datetime;
}

export interface TrackedObject extends TimeStampedObject {
    // Name of the user that has originally created the content
    createdBy: string;
    // Name of the user that has last updated the content
    updatedBy: string;
}

export interface VocabularyEntry {
    name: string;
    code: string;
    hidden?: boolean;
}

export type IdentifierList = ReferenceItem[];

export interface ReferenceItem {
    id: uuid;
}

export type Person = ReferenceItem & TrackedObject & PersonData;

export interface PersonData {
    firstName: string;
    lastName: string;
}

export type Organization = ReferenceItem & TrackedObject & OrganizationData;

export interface OrganizationData {
    name: string;
}

export interface EmbeddedEntities<K extends string, T> {
    _embedded?: {
        [P in K]: T[];
    };
}

export function getEmbeddedEntities<K extends string, T>(embeddedEntities: EmbeddedEntities<K, T>, key: K): T[] {
    return embeddedEntities._embedded ? embeddedEntities._embedded[key] : [];
}

export interface NamedValue {
    name: string;
    value: string;
}
