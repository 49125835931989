export enum CartActionType {
    CREATE_CART = 'CART/CREATE',
    RESET_CART = 'CART/RESET',
    SET_IS_READY = 'CART/SET_IS_READY',
    ADD_OR_UPDATE_CART_ITEM = 'CART/ADD_OR_UPDATE_CART_ITEM',
    ADD_TO_CART_SUCCESS = 'CART/ADD_SUCCESS',
    ALREADY_IN_CART_ERROR = 'CART/ALREADY_IN_CART_ERROR',
    ORDERED_RECENTLY_ERROR = 'CART/ORDERED_RECENTLY_ERROR',
    REMOVE_FROM_CART = 'CART/REMOVE',
    EDIT_ITEM = 'CART/EDIT_ITEM',
    CLEAR_CART = 'CART/CLEAR',
}
