import { FrontUser, ProfileType } from '../user';

export function isProfessional(user: FrontUser): boolean {
    return user.profileType === ProfileType.PROFESSIONAL;
}

export function getAvailableCredits(user: FrontUser, numberOfCartItems: number): number {
    if (user.availableCredits === undefined) {
        return 0;
    }
    return user.availableCredits - numberOfCartItems;
}

export function hasEnoughCreditsToOrder(user: FrontUser, numberOfCartItems: number): boolean {
    if (isProfessional(user)) {
        return getAvailableCredits(user, numberOfCartItems) > 0;
    }
    return true;
}
