import { MovieActionType } from './MovieActionType';
import { uuid } from '../../domain/core';
import { MovieStateData } from './MovieStateReducer';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export interface MovieAction extends FetchAction<MovieActionType, Partial<MovieStateData>> {
    id?: uuid;
}

export function createFetchMovieAction(id: uuid): MovieAction {
    return {
        type: MovieActionType.FETCH_MOVIE,
        id,
    };
}

export function createFetchPurchasableMovieProductsAction(id: uuid): MovieAction {
    return {
        type: MovieActionType.FETCH_MOVIE_PRODUCTS,
        id,
    };
}

export function createFetchMovieSuccessAction(payload: Partial<MovieStateData>): MovieAction {
    return {
        type: MovieActionType.FETCH_MOVIE_SUCCESS,
        payload,
    };
}

export function createFetchMovieErrorAction(error: RequestError): MovieAction {
    return {
        type: MovieActionType.FETCH_MOVIE_ERROR,
        payload: error,
    };
}

export function createDisplayPurchasableProductsAction(id: uuid): MovieAction {
    return {
        type: MovieActionType.DISPLAY_PURCHASABLE_PRODUCTS,
        id,
    };
}

export function createFetchMovieResourcesAction(id: uuid): MovieAction {
    return {
        type: MovieActionType.FETCH_MOVIE_RESOURCES,
        id,
    };
}
