import { takeLatest, call, put, select, take } from 'redux-saga/effects';
import * as actions from './MovieActions';
import { MovieActionType } from './MovieActionType';
import { callApi } from '../../sagas/APICallSaga';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { MovieStateData } from './MovieStateReducer';
import { Contribution, Movie, MovieAsset } from '../../domain/work';
import { EmbeddedEntities, getEmbeddedEntities } from '../../domain/core';
import { Cycle } from '../../domain/editorial';
import { LegalContract } from '../../domain/legal';
import { VideoProduct } from '../../domain/commerce';
import { LoginStatus } from '../auth';
import { AppState } from '../../store/reducers';
import {
    createToggleConnectionModalAction,
    createToggleNotEnoughCreditsErrorModalAction,
    createToggleOrderModalAction,
} from '../UI';
import { CartActionType } from '../cart';
import { FrontUser } from '../../domain/user';
import { ProfileActionType } from '../profile';
import { PlayerVideoList, PlayerVideo } from '../../domain/player';
import { Review } from '../../domain/community';
import { hasEnoughCreditsToOrder } from '../../domain/utils/UserUtils';

function* fetchMovie(action: actions.MovieAction): SagaIterator {
    yield call(callApi, {
        endpoint: [
            `movies/${action.id}`,
            `movies/${action.id}/cycles`,
            `movies/${action.id}/contracts`,
            `movies/${action.id}/products`,
            `movies/${action.id}/resources?size=1000`,
            `movies/${action.id}/related`,
            `movies/${action.id}/reviews`,
            `movies/${action.id}/contributions`,
        ],
        onSuccess: (
            movie: AxiosResponse<Movie>,
            cycles: AxiosResponse<EmbeddedEntities<'cycles', Cycle>>,
            contracts: AxiosResponse<EmbeddedEntities<'legalContracts', LegalContract>>,
            products: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>,
            resources: AxiosResponse<EmbeddedEntities<'resources', MovieAsset>>,
            relatedMovies: AxiosResponse<EmbeddedEntities<'movies', Movie>>,
            reviews: AxiosResponse<EmbeddedEntities<'userReviews', Review>>,
            contributions: AxiosResponse<EmbeddedEntities<'contributions', Contribution>>
        ): actions.MovieAction => {
            const reviewsList = getEmbeddedEntities(reviews.data, 'userReviews');
            reviewsList.reverse();

            const payload: MovieStateData = {
                ...movie.data,
                cycles: getEmbeddedEntities(cycles.data, 'cycles'),
                legalContracts: getEmbeddedEntities(contracts.data, 'legalContracts'),
                videoProducts: getEmbeddedEntities(products.data, 'videoProducts'),
                resources: getEmbeddedEntities(resources.data, 'resources'),
                relatedMovies: getEmbeddedEntities(relatedMovies.data, 'movies'),
                reviews: reviewsList,
                contributions: getEmbeddedEntities(contributions.data, 'contributions'),
            };

            return actions.createFetchMovieSuccessAction(payload);
        },
        onError: actions.createFetchMovieErrorAction,
    });
}

function* fetchPurchasableMovieProducts(action: actions.MovieAction): SagaIterator {
    yield call(callApi, {
        endpoint: [`movies/${action.id}`, `movies/${action.id}/contracts`, `movies/${action.id}/products`],
        onSuccess: (
            movie: AxiosResponse<Movie>,
            contracts: AxiosResponse<EmbeddedEntities<'legalContracts', LegalContract>>,
            videoProducts: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>
        ): actions.MovieAction => {
            const products = getEmbeddedEntities(videoProducts.data, 'videoProducts');
            const payload: Partial<MovieStateData> = {
                ...movie.data,
                legalContracts: getEmbeddedEntities(contracts.data, 'legalContracts'),
                videoProducts: products,
            };
            return actions.createFetchMovieSuccessAction(payload);
        },
        onError: actions.createFetchMovieErrorAction,
    });
}

const selectUser = (state: AppState): FrontUser | undefined => state.profile.data;

function* displayPurchasableProducts(action: actions.MovieAction): SagaIterator {
    const status = yield select((state: AppState): LoginStatus => state.auth.loginStatus);

    if (status === LoginStatus.ANONYMOUS) {
        yield put(createToggleConnectionModalAction());
        yield take(CartActionType.SET_IS_READY);
    } else {
        let user: FrontUser | undefined = yield select(selectUser);
        if (!user) {
            yield take([ProfileActionType.FETCH_SUCCESS, ProfileActionType.FETCH_ERROR]);
        }
        user = yield select(selectUser);
        if (!user) {
            // console.error('no user found, aborting'); // FIXME should probably display an error modal
            return;
        }

        const numberOfCartItems: number = yield select((state: AppState): number => state.cart.items.length);
        if (!hasEnoughCreditsToOrder(user, numberOfCartItems)) {
            yield put(createToggleNotEnoughCreditsErrorModalAction());
            return;
        }

        const movie: MovieStateData | undefined = yield select(
            (state: AppState): MovieStateData | undefined => state.movie.data
        );

        if (!movie || movie.id !== action.id) {
            yield put(actions.createFetchPurchasableMovieProductsAction(action.id!));
            yield take(MovieActionType.FETCH_MOVIE_SUCCESS);
        }

        yield put(createToggleOrderModalAction());
    }
}

function* fetchMovieResources(action: actions.MovieAction): SagaIterator {
    yield call(callApi, {
        endpoint: [`movies/${action.id}`, `movies/${action.id}/resources`, 'player-videos?size=1000'],
        onSuccess: (
            movie: AxiosResponse<Movie>,
            resources: AxiosResponse<EmbeddedEntities<'resources', MovieAsset>>,
            videosResp: AxiosResponse<PlayerVideoList>
        ): actions.MovieAction => {
            const videos = getEmbeddedEntities(videosResp.data, 'videos');
            const videoCorrespondingToMovie = videos.find(
                (playerVideo: PlayerVideo): boolean => playerVideo.id === movie.data.id
            );
            if (videoCorrespondingToMovie && videoCorrespondingToMovie.licenseAdditionalParam) {
                movie.data['licenseAdditionalParam'] = videoCorrespondingToMovie.licenseAdditionalParam;
            }
            const payload: Partial<MovieStateData> = {
                ...movie.data,
                resources: getEmbeddedEntities(resources.data, 'resources'),
            };

            return actions.createFetchMovieSuccessAction(payload);
        },
        onError: actions.createFetchMovieErrorAction,
    });
}

export default function* movieSaga(): SagaIterator {
    yield takeLatest(MovieActionType.FETCH_MOVIE, fetchMovie);

    yield takeLatest(MovieActionType.DISPLAY_PURCHASABLE_PRODUCTS, displayPurchasableProducts);
    yield takeLatest(MovieActionType.FETCH_MOVIE_PRODUCTS, fetchPurchasableMovieProducts);
    yield takeLatest(MovieActionType.FETCH_MOVIE_RESOURCES, fetchMovieResources);
}
