import { CartAction, CartItemCRUDAction, CreateCartAction } from './CartActions';
import { CartActionType } from './CartActionType';
import { CartEntry } from '../../domain/commerce';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

export enum CRUDAction {
    CREATE,
    UPDATE,
    DELETE,
}

export interface CartState {
    items: CartEntry[];
    isReady: boolean;
    indexOfItemBeingEdited: number | null;
    lastActionPerformed?: CRUDAction;
}

export const initialState: CartState = {
    items: [],
    isReady: false,
    indexOfItemBeingEdited: null,
};

export const cartReducer = (state: CartState = initialState, action: CartAction): CartState => {
    switch (action.type) {
        case CartActionType.CREATE_CART:
            return {
                ...state,
                items: (action as CreateCartAction).items,
            };

        case CartActionType.SET_IS_READY:
            return {
                ...state,
                isReady: true,
            };

        case CartActionType.ADD_TO_CART_SUCCESS:
            let items;
            let actionPerformed: CRUDAction = CRUDAction.CREATE;
            if (state.indexOfItemBeingEdited !== null) {
                items = Object.assign([], state.items, {
                    [state.indexOfItemBeingEdited]: (action as CartItemCRUDAction).item,
                });
                actionPerformed = CRUDAction.UPDATE;
            } else {
                items = [...state.items, (action as CartItemCRUDAction).item];
            }
            return {
                ...state,
                indexOfItemBeingEdited: null,
                items: uniqWith(items, isEqual),
                lastActionPerformed: actionPerformed,
            };

        case CartActionType.REMOVE_FROM_CART:
            const itemToRemove = (action as CartItemCRUDAction).item;
            return {
                ...state,
                items: filter(
                    state.items,
                    (item: CartEntry): boolean =>
                        itemToRemove.videoProduct.id !== item.videoProduct.id ||
                        get(itemToRemove, 'subtitlesLanguage.id', null) !== get(item, 'subtitlesLanguage.id', null)
                ),
            };

        case CartActionType.CLEAR_CART:
            return {
                ...state,
                items: [],
            };

        case CartActionType.EDIT_ITEM:
            return { ...state, indexOfItemBeingEdited: findIndex(state.items, (action as CartItemCRUDAction).item) };

        default:
            return state;
    }
};
