import { takeEvery, takeLatest, call, fork, select, take, put } from 'redux-saga/effects';
import * as service from './CartService';
import { SagaIterator } from 'redux-saga';
import { CartActionType } from './CartActionType';
import { waitForAuthentication, AuthActionType } from '../auth';
import { CartLocalStorageEntry, CartEntry, VideoProduct, Order, ProductType } from '../../domain/commerce';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import map from 'lodash/map';
import { callApi } from '../../sagas/APICallSaga';
import { AnyAction } from 'redux';
import { AppState } from '../../store/reducers';
import { AxiosResponse } from 'axios';
import { EmbeddedEntities, getEmbeddedEntities, uuid } from '../../domain/core';
import {
    createCreateCartAction,
    CreateCartAction,
    createSetCartIsReadyAction,
    CartItemCRUDAction,
    createAddToCartSuccessAction,
    createAlreadyInCartErrorAction,
    createOrderedRecentlyErrorAction,
} from './CartActions';
import {
    createToggleMiniCartAction,
    createToggleOrderModalAction,
    createToggleSuccessfullyAddedToCartModalAction,
    createToggleAlreadyInCartErrorModalAction,
    createToggleOrderedRecentlyErrorModalAction,
} from '../UI';
import { createDisplayPurchasableProductsAction } from '../movie';
import { CRUDAction } from './CartStateReducer';
import dayjs from 'dayjs';

function* syncLocalStorage(): SagaIterator {
    const cartItems: CartEntry[] = yield select((state: AppState): CartEntry[] => state.cart.items);
    if (cartItems.length === 0) {
        yield call(service.clear);
    } else {
        yield call(service.save, cartItems);
    }
}

function* setCartIsReady(): SagaIterator {
    yield put(createSetCartIsReadyAction());
}

function* initCart(): SagaIterator {
    const storedItems: CartLocalStorageEntry[] = yield call(service.load);
    if (storedItems.length > 0) {
        yield call(callApi, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            endpoint: { url: 'video-products', params: { q_id: map(storedItems, first) } },
            onSuccess: (res: AxiosResponse<EmbeddedEntities<'videoProducts', VideoProduct>>): CreateCartAction => {
                const products = getEmbeddedEntities(res.data, 'videoProducts');
                return createCreateCartAction(service.transformAPIResponse(products, storedItems));
            },
            onError: (_err): AnyAction => {
                // console.error('Unable to recreate cart'); // FIXME : Use a proper logging lib
                return createSetCartIsReadyAction();
            },
        });
    } else {
        yield put(createCreateCartAction([]));
    }
}

function* cartInitWatcher(): SagaIterator {
    while (true) {
        yield call(waitForAuthentication);
        yield call(initCart);
        yield take(AuthActionType.DEAUTHENTICATE);
    }
}

function* hideMiniCartIfEmpty(): SagaIterator {
    const totalItems: number = yield select((state: AppState): number => state.cart.items.length);
    const miniCartIsOpen: boolean = yield select((state: AppState): boolean => state.UI.miniCartIsOpen);

    if (totalItems === 0 && miniCartIsOpen) {
        yield put(createToggleMiniCartAction());
    }
}

function* hideOrderModalIfOpen(): SagaIterator {
    if (yield select((state: AppState): boolean => state.UI.orderModalIsOpen)) {
        yield put(createToggleOrderModalAction());
    }
}

function* onAddToCartSuccess(action: CartItemCRUDAction): SagaIterator {
    yield call(hideOrderModalIfOpen);
    const actionPerformed: CRUDAction | undefined = yield select(
        (state: AppState): CRUDAction | undefined => state.cart.lastActionPerformed
    );
    if (actionPerformed === CRUDAction.CREATE) {
        yield put(createToggleSuccessfullyAddedToCartModalAction(action.item));
    }
}

function* displayAlreadyInCartModal(): SagaIterator {
    yield call(hideOrderModalIfOpen);
    yield put(createToggleAlreadyInCartErrorModalAction());
}

function* displayOrderedRecentlyModal(): SagaIterator {
    yield call(hideOrderModalIfOpen);
    yield put(createToggleOrderedRecentlyErrorModalAction());
}

export function* hasOrderedProductThePast30Days(product: VideoProduct): SagaIterator {
    yield fork(callApi, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        endpoint: { url: `users/orders`, params: { q_product_id: product.id } },
        onSuccess: (res): AnyAction => {
            const orders: Order[] = get(res.data, '_embedded.orders', []);
            let hasOrderedProduct = false;
            if (orders.length) {
                hasOrderedProduct =
                    findIndex(
                        orders,
                        (order: Order): boolean => dayjs().diff(dayjs(order.orderedAt), 'days', true) < 30
                    ) !== -1;
            }
            return { type: 'CART/PAST_ORDERS_CHECK_SUCCESS', hasOrderedProduct };
        },
        onError: (_err): AnyAction => {
            return { type: 'NOOP' };
        },
    });

    const result = yield take('CART/PAST_ORDERS_CHECK_SUCCESS');
    return result.hasOrderedProduct;
}

function* addToCart(action: CartItemCRUDAction): SagaIterator {
    const cartItems: CartEntry[] = yield select((state: AppState): CartEntry[] => state.cart.items);
    const isAlreadyInCart =
        findIndex(
            cartItems,
            (item: CartEntry): boolean =>
                action.item.videoProduct.id === item.videoProduct.id &&
                action.item.quantity === item.quantity &&
                get(action.item, 'subtitlesLanguage.code', null) === get(item, 'subtitlesLanguage.code', null)
        ) !== -1;

    let hasOrderedProductRecently = false;
    if (action.item.videoProduct.type.productType === ProductType.DIGITAL) {
        hasOrderedProductRecently = yield call(hasOrderedProductThePast30Days, action.item.videoProduct);
    }

    if (!isAlreadyInCart && !hasOrderedProductRecently) {
        yield put(createAddToCartSuccessAction(action.item));
    } else {
        yield put(
            hasOrderedProductRecently
                ? createOrderedRecentlyErrorAction(action.item)
                : createAlreadyInCartErrorAction(action.item)
        );
    }
}

function* toggleOrderModal(): SagaIterator {
    const index = yield select((state: AppState): number | null => state.cart.indexOfItemBeingEdited);
    if (index === null) {
        // console.error('No item currently being edited, aborting'); // FIXME: USe a proper logging lib
        return;
    }
    const movieId = yield select((state: AppState): uuid => state.cart.items[index].videoProduct.movie!.id);
    yield put(createDisplayPurchasableProductsAction(movieId));
}

export default function* cartSaga(): SagaIterator {
    if (typeof window !== 'object') {
        return;
    }
    yield fork(cartInitWatcher);
    yield takeLatest(
        [CartActionType.ADD_TO_CART_SUCCESS, CartActionType.REMOVE_FROM_CART, CartActionType.CLEAR_CART],
        syncLocalStorage
    );
    yield takeLatest(CartActionType.RESET_CART, initCart);
    yield takeEvery(CartActionType.ADD_OR_UPDATE_CART_ITEM, addToCart);
    yield takeEvery(CartActionType.ADD_TO_CART_SUCCESS, onAddToCartSuccess);
    yield takeEvery(CartActionType.ALREADY_IN_CART_ERROR, displayAlreadyInCartModal);
    yield takeEvery(CartActionType.ORDERED_RECENTLY_ERROR, displayOrderedRecentlyModal);
    yield takeEvery(CartActionType.REMOVE_FROM_CART, hideMiniCartIfEmpty);
    yield takeEvery(CartActionType.EDIT_ITEM, toggleOrderModal);
    yield takeEvery(CartActionType.CREATE_CART, setCartIsReady);
}
