import { CartActionType } from './CartActionType';
import { Action } from 'redux';
import { CartEntry } from '../../domain/commerce';

export type CartAction = Action<CartActionType>;

export interface CreateCartAction extends CartAction {
    items: CartEntry[];
}

export interface CartItemCRUDAction extends CartAction {
    item: CartEntry;
}

export function createCreateCartAction(items: CartEntry[]): CreateCartAction {
    return {
        type: CartActionType.CREATE_CART,
        items,
    };
}

export function createAddOrUpdateCartItemAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.ADD_OR_UPDATE_CART_ITEM,
        item,
    };
}

export function createAddToCartSuccessAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.ADD_TO_CART_SUCCESS,
        item,
    };
}

export function createAlreadyInCartErrorAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.ALREADY_IN_CART_ERROR,
        item,
    };
}

export function createOrderedRecentlyErrorAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.ORDERED_RECENTLY_ERROR,
        item,
    };
}

export function createRemoveFromCartAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.REMOVE_FROM_CART,
        item,
    };
}

export function createEditCartItemAction(item: CartEntry): CartItemCRUDAction {
    return {
        type: CartActionType.EDIT_ITEM,
        item,
    };
}

export function createClearCartAction(): CartAction {
    return {
        type: CartActionType.CLEAR_CART,
    };
}

export function createSetCartIsReadyAction(): CartAction {
    return {
        type: CartActionType.SET_IS_READY,
    };
}

export function createResetCartAction(): CartAction {
    return {
        type: CartActionType.RESET_CART,
    };
}
