import { ReferenceItem, TrackedObject, datetime, VocabularyEntry } from './core';
import { OrderLine } from './commerce';

export type AdminUser = ReferenceItem & AdminUserData & TrackedObject;

export enum AdminUserRole {
    SUPER_ADMIN,
    LOCAL_ADMIN,
}

export interface AdminUserData {
    email: string;
    displayName: string;
    userName: string;
    role: AdminUserRole;
}

export type AdminUserUpdate = ReferenceItem & Required<AdminUserData>;

export type Centre = ReferenceItem & TrackedObject & CentreData;

export interface CentreData {
    name: string;
    address: string;
    zipcode: string;
    city: string;
    type: CentreType;
    country: string;
    profileType: ProfileType;
}

export type CentreType = ReferenceItem & CentreTypeData & TrackedObject;

export interface CentreTypeData extends VocabularyEntry {
    profileType: ProfileType;
}

export type CentreTypeUpdate = ReferenceItem & Required<CentreTypeData>;

export type FrontUser = ReferenceItem & FrontUserData & TrackedObject;

export enum ProfileType {
    PROFESSIONAL = 'professional',
    IF = 'if',
}

export enum ProfileSubtype {
    CULTURAL_PARTNER = 'cultural-partner',
    TEACHER = 'teacher',
    PUBLIC = 'public',
}

export interface FrontUserData {
    profileType: ProfileType;
    email?: string;
    enabled?: boolean;
    givenName?: string;
    lastName?: string;
    centre?: Centre;
    legacyCentre?: Centre;
    password?: string;
    legacyPassword?: string;
    newsletter?: boolean;
    education?: boolean;
    job?: UserJob;
    jobAdditionalInfo?: string;
    availableCredits?: number;
    registerCompleted?: boolean;
    geolocationUnlocked?: boolean;
}

export type FrontUserUpdate = ReferenceItem & FrontUserData;

export type UserJob = ReferenceItem & UserJobData & TrackedObject;

export interface UserJobData extends VocabularyEntry {
    profileType: ProfileType;
}

export type UserJobUpdate = ReferenceItem & Required<UserJobData>;

export type ScreeningEvaluation = ReferenceItem & ScreeningEvaluationData & TrackedObject;

export enum ScreeningEvaluationStatus {
    COMPLETED = 'completed',
    PENDING = 'pending',
}

export interface ScreeningEvaluationData {
    status: ScreeningEvaluationStatus;
    user: Partial<FrontUser>;
    orderLine: OrderLine;
    screeningEvents?: ScreeningEvent[];
}

export type ScreeningEvent = ReferenceItem & ScreeningEventData & TrackedObject;

export interface ScreeningEventData {
    youngAudience: boolean;
    debated: boolean;
    education: boolean;
    charged: boolean;
    screeningDate: datetime;
    spectatorCount: number;
    user: Partial<FrontUser>;
    orderLine: Partial<OrderLine>;
}

export interface CreditRequest {
    amount: number;
    description: string;
}
