import { CartEntry, CartLocalStorageEntry, VideoProduct, CatalogLanguage } from '../../domain/commerce';
import compact from 'lodash/compact';
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';

const convertCartEntryForStorage = ({
    videoProduct,
    subtitlesLanguage,
    quantity,
}: CartEntry): CartLocalStorageEntry => [videoProduct.id, get(subtitlesLanguage, 'code', undefined), quantity];

export const save = (cartItems: CartEntry[]): void =>
    localStorage.setItem('cart', JSON.stringify(map(cartItems, convertCartEntryForStorage)));

export const load = (): CartLocalStorageEntry[] => {
    const serializedItems = localStorage.getItem('cart');
    return serializedItems ? JSON.parse(serializedItems) : [];
};

export const transformAPIResponse = (
    productsFromAPI: VideoProduct[],
    productsFromLocalStorage: CartLocalStorageEntry[]
): CartEntry[] =>
    compact(
        map(productsFromLocalStorage, ([id, languageCode, quantity]): CartEntry | undefined => {
            const entry: Partial<CartEntry> = {};
            const foundProduct = find(productsFromAPI, (product: VideoProduct): boolean => product.id === id);
            if (foundProduct) {
                entry['videoProduct'] = foundProduct;
            } else {
                return undefined;
            }
            if (quantity) {
                entry['quantity'] = quantity;
            }
            if (!languageCode) {
                return entry as CartEntry;
            }
            const foundLanguage = find(
                foundProduct.subtitleLanguages,
                (language: CatalogLanguage): boolean => language.code === languageCode
            );
            if (foundLanguage) {
                entry['subtitlesLanguage'] = foundLanguage;
            } else {
                return undefined;
            }
            return entry as CartEntry;
        })
    );

export const clear = (): void => localStorage.removeItem('cart');
